<template>
  <Breadcrumb v-if="detailTitle" :detailTitle="detailTitle.toUpperCase()" />
  <Breadcrumb v-else detailTitle="&nbsp;" />
  <template v-if="!menuListRequestEnd">
    <SetLoader classes="mt-2" />
  </template>
  <template v-else>
    <div class="row">
      <div class="col-md-2 p-0">
        <div
          class="nav flex-column nav-pills p-0 sticky-block-dxgrid"
          id="v-pills-tab"
          role="tablist"
          aria-orientation="vertical"
        >
          <template v-if="isSideBarMenu">
            <button
              class="nav-link active"
              data-bs-toggle="pill"
              data-bs-target="#v-pills-sidebar-items"
              @click="sidebarTabActive = true"
              type="button"
              role="tab"
              aria-selected="true"
            >
              {{
                $t(
                  "Menus.MenuItems.SidebarItems",
                  {},
                  { locale: this.$store.state.activeLang }
                )
              }}
            </button>
            <button
              class="nav-link"
              data-bs-toggle="pill"
              data-bs-target="#v-pills-header-items"
              @click="sidebarTabActive = false"
              type="button"
              role="tab"
              aria-selected="false"
            >
              {{
                $t(
                  "Menus.MenuItems.NavbarItems",
                  {},
                  { locale: this.$store.state.activeLang }
                )
              }}
            </button>
          </template>

          <div class="col-12" :class="{ 'mt-2': isSideBarMenu }">
            <router-link
              class="btn btn-success w-100"
              :to="{
                name: 'MenuNewItem',
                params: {
                  menuId: this.$route.params.menuId,
                  tabSidebarClick: isSideBarMenu && sidebarTabActive,
                },
              }"
            >
              <i class="bi bi-plus"></i>
              {{
                $t(
                  "Menus.MenuItems.NewMenuItem",
                  {},
                  { locale: this.$store.state.activeLang }
                )
              }}
            </router-link>

            <router-link
              class="btn btn-warning w-100 mt-2"
              :to="{
                name: 'MenuEdit',
                params: { menuId: this.$route.params.menuId },
              }"
            >
              <i class="bi bi-pencil-square"></i>
              {{
                $t("Buttons.Edit", {}, { locale: this.$store.state.activeLang })
              }}
            </router-link>
            <!-- <button
              type="button"
              class="btn btn-danger w-100 mt-2"
              data-bs-toggle="modal"
              data-bs-target="#modalDelete"
            >
              <i class="bi bi-trash"></i>
              {{
                $t(
                  "Buttons.Delete",
                  {},
                  { locale: this.$store.state.activeLang }
                )
              }}
            </button> -->
            <button type="button" class="btn btn-info mt-2 w-100">
              <i class="bi bi-tree"></i>
              {{
                $t(
                  "Menus.MenuItems.MenuTree",
                  {},
                  { locale: this.$store.state.activeLang }
                )
              }}
            </button>
          </div>
        </div>
      </div>
      <div class="col-md-10">
        <Grid
          v-if="sidebarTabActive && isSideBarMenu"
          :settings="gridSettings"
          :columns="gridColumns"
          :rows="menuSidebarItemList"
          @changeOrderSuccessful="getMenuItemList"
        />
        <Grid
          v-else
          :settings="gridSettings"
          :columns="gridColumns"
          :rows="menuMenuItemList"
          @changeOrderSuccessful="getMenuItemList"
          @onTranslationButtonClick="onTranslationButtonClick"
        />
      </div>
    </div>
    <ModalDelete
      :deleteRecordId="this.$route.params.menuId"
      :isGatewayRequest="true"
      deleteActionUrl="/Lcdp-MenuDelete"
      @deleteAfterCallMethod="returnMenuList()"
    />
    <TranslationModal
      :keyTypeId="keyTypeId"
      :parentPublicId="parentPublicId"
      :formulaName="formulaName"
      :keyName="keyName"
    />
  </template>
</template>
<script>
import { showModal } from "@/core/helpers/dom";
export default {
  name: "MenuListItem",
  components: {},
  data() {
    return {
      keyTypeId: 7,
      parentPublicId: "",
      formulaName: "",
      keyName: "",
      gridSettings: {
        action: "MenuListItem",
        requestUrl: String.format(""),
        requestUrlRouteParamNames: [],
        routeRequiredParameters: [
          {
            key: "menuId",
            value: "menuPublicId",
            isRouteParameter: false,
          },
          {
            key: "menuItemId",
            value: "publicId",
            isRouteParameter: false,
          },
        ],

        serialNoLink: `#/Menu/Detail/${this.$route.params.menuId}&menuItemId=`,

        allowSearchPanel: true,
        allowExcelExport: false,
        allowPaging: false,
        allowDeleting: true,
        deleteActionUrl: "/Lcdp-MenuDeleteItem",
        isGatewayRequest: true,

        allowDragAndDrop: true,
        changeOrderUrl: "/Lcdp-MenuChangeOrder",
        changeOrderModelParentProperties: [
          { key: "parentPublicId", value: "menuId" },
        ],
        autoOrderProcess: false,

        buttons: [
          {
            name: "detail",
            cssClass: "btn-primary",
            iconClass: "bi-list-task",
            routeButton: true,
            routeObj: {
              name: "MenuItemListDetail",
              params: {
                menuId: "",
                menuItemId: "",
              },
            },
          },
          {
            name: "edit",
            cssClass: "btn-warning",
            iconClass: "bi-pencil-square",
            routeButton: true,
            routeObj: {
              name: "MenuEditItem",
              params: {
                menuId: "",
                menuItemId: "",
              },
            },
          },
        ],
      },
      gridColumns: [
        {
          text: this.$t(
            "Menus.Name",
            {},
            { locale: this.$store.state.activeLang }
          ),
          field: "name",
          type: "string",
          visible: true,
          width: 300,
          template: "",
          format: "",
          textAlign: "",
        },
        {
          text: this.$t(
            "Menus.FormulaName",
            {},
            { locale: this.$store.state.activeLang }
          ),
          field: "formulaName",
          type: "string",
          visible: true,
          width: 0,
          template: "",
          format: "",
          textAlign: "",
        },
        {
          text: this.$t(
            "Menus.Url",
            {},
            { locale: this.$store.state.activeLang }
          ),
          field: "url",
          type: "string",
          visible: true,
          width: 300,
          template: "",
          format: "",
          textAlign: "",
        },
        {
          text: this.$t(
            "BaseModelFields.IsActive",
            {},
            { locale: this.$store.state.activeLang }
          ),
          field: "isActive",
          type: "boolean",
          visible: true,
          width: 200,
          template: "",
          format: this.$store.getters._dateFormat,
          textAlign: "",
        },
      ],
      sidebarTabActive: true,
      menuData: [],
      isSideBarMenu: false,
      menuItemList: [],
      menuSidebarItemList: [],
      menuListRequestEnd: false,
      menuMenuItemList: [],
      detailTitle: "",
    };
  },
  methods: {
    getMenuItemList() {
      this.menuListRequestEnd = false;
      this.$prodGatewayAxios
        .get(
          String.format("/Lcdp-MenuListItem?id={0}", this.$route.params.menuId)
        )
        .then((response) => {
          this.menuListRequestEnd = true;
          this.menuItemList = response.data;
          this.menuSidebarItemList = response.data.filter((item) => {
            return item.isSideBarItem;
          });
          this.menuMenuItemList = response.data.filter((item) => {
            return !item.isSideBarItem;
          });
        })
        .catch(function (error) {
          //alert(error);
        });
    },
    returnMenuList() {
      setTimeout(() => {
        this.$router.push({ name: "MenuList" });
      }, 400);
    },
    getMenuDetail() {
      this.$prodGatewayAxios
        .get(String.format("/Lcdp-MenuGet?id={0}", this.$route.params.menuId))
        .then((response) => {
          this.menuData = response.data;
          this.detailTitle = response.data.name;
          this.isSideBarMenu = response.data.isSideBarActive;
        })
        .catch(function (error) {
          //alert(error);
        });
    },
    onTranslationButtonClick(rowData) {
      this.formulaName = rowData.formulaName;
      this.keyName = rowData.name;
      this.parentPublicId = this.menuData.publicId;
      showModal(document.getElementById("translationModal"));
    },
  },
  mounted() {
    this.getMenuDetail();
    this.getMenuItemList();
    if (this.$store.getters._isMultiLanguage) {
      this.gridSettings.buttons.push({
        name: "translate",
        cssClass: "btn-primary btn-light translation",
        iconClass: "bi bi-translate",
        routeButton: false,
        emitMethodName: "onTranslationButtonClick",
        attributes: {
          "data-bs-toggle": "modal",
          "data-bs-target": "#translationModal",
        },
        iconAttributes: {
          "data-bs-toggle": "tooltip",
          "data-bs-placement": "top",
          title: this.$t(
            "Components.TranslationModal.Title",
            {},
            { locale: this.$store.state.activeLang }
          ),
        },
      });
    }
  },
};
</script>
